<template>
  <div>
    <div class="d-flex justify-content-between mt-6">
      <div class="d-flex pointer" @click="$router.back()">
        <rm-base-icon class="mr-3" name="arrow-left" />
        <p>Go Back</p>
      </div>
      <div>
        <rm-base-button
          small
          bgColor="primary"
          text="Create Subscription"
          preppend-icon-inner="plus-circle"
          @click="showPaymentModal"
        ></rm-base-button>
      </div>
    </div>
    <b-row class="bg-white overflow-auto rounded-lg p-3 pt-5 pb-5 mt-4 ">
      <b-col cols="4">
        <div class="d-flex flex-column align-items-center">
          <b-avatar
            class="mb-3"
            variant="info"
            size="140"
            src="https://placekitten.com/300/300"
          ></b-avatar>
          <rm-base-button
            v-if="false"
            small
            bgColor="primary"
            text="Edit"
            preppend-icon-inner="plus-circle"
          ></rm-base-button>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="text-subheader text-black my-3">Bio Data</div>
        <div class="d-flex mb-3">
          <div class="mr-3">
            <rm-base-icon name="account" />
          </div>
          <div>
            {{ client.first_name }}
            {{ client.middle_name }}
            {{ client.last_name }}
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="mr-3">
            <rm-base-icon name="mail" />
          </div>
          <div>{{ client.email }}</div>
        </div>
        <div class="d-flex mb-3">
          <div class="mr-3">
            <rm-base-icon name="phone" />
          </div>
          <div>{{ client.phone_number }}</div>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="text-subheader text-black my-3">Next Of Kin Data</div>
        <div class="d-flex mb-3">
          <div class="mr-3">
            <rm-base-icon name="account" />
          </div>
          <div>{{ client.next_of_kin_full_name }}</div>
        </div>
        <div class="d-flex mb-3">
          <div class="mr-3">
            <rm-base-icon name="mail" />
          </div>
          <div>{{ client.next_of_kin_email }}</div>
        </div>
        <div class="d-flex mb-3">
          <div class="mr-3">
            <rm-base-icon name="phone" />
          </div>
          <div>{{ client.next_of_kin_phone }}</div>
        </div>
        <div v-if="false" class="d-flex gap-1 justify-content-between w-100">
          <rm-base-button
            small
            bgColor="primary"
            text="Send Email"
            preppend-icon-inner="plus-circle"
          ></rm-base-button>
          <rm-base-button
            small
            bgColor="primary"
            text="Send SMS"
            preppend-icon-inner="plus-circle"
          ></rm-base-button>
        </div>
      </b-col>
      <b-col cols="6">
        <balance-card :balance="wallet.balance" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <div class="bg-white overflow-auto rounded-lg p-4 mt-4">
          <b-tabs pills content-class="mt-3" align="left">
            <b-tab exact-active-class="active" title="Commissions">
              <client-commission-table :realtorId="$route.params?.id" />
            </b-tab>
            <b-tab exact-active-class="active" title="Wallet History">
              <wallet-history-table :realtorId="$route.params?.id" />
            </b-tab>
            <!-- <b-tab exact-active-class="active" title="Change Requests">
              <rm-request-table />
            </b-tab> -->
            <!-- <b-tab exact-active-class="active" title="Allocations">
              <rm-allocations-table />
            </b-tab> -->
            <!-- <b-tab exact-active-class="active" title="Discounts">
              <h1>Discounts</h1>
            </b-tab> -->
            <!-- <b-tab exact-active-class="active" title="Documents">
              <h1>Documents</h1>
            </b-tab> -->
          </b-tabs>
        </div>
      </b-col>
    </b-row>

    <rm-base-modal id="create-subscription-modal" size="lg">
      <create-subscription-modal />
    </rm-base-modal>
  </div>
</template>

<script>
import ClientCommissionTable from '../commissions/components/RmCommissionsTable.vue';
import WalletHistoryTable from './components/RmWalletHistoryTable.vue';
import CreateSubscriptionModal from './components/RmCreateSubscriptionModal.vue';
import BalanceCard from "./components/RmBalanceCard.vue";
// import RmClientPurchaseTable from './components/RmClientPurchaseTable.vue';
// import RmRequestTable from '../all-requests/components/rmRequestTable.vue';
// import RmAllocationsTable from '../pending-allocations/components/RmAllocationsTable.vue';

export default {
  components: {
    // RmSalesTable,
    // RmRequestTable,
    // RmClientPurchaseTable,
    // RmAllocationsTable,
    ClientCommissionTable,
    CreateSubscriptionModal,
    WalletHistoryTable,
    BalanceCard,
  },
  data() {
    return {
      profile: {},
    };
  },
  computed: {
    client() {
      return  this.profile ?? {};
    },
    wallet() {
      return (this.client && this.client.wallet) ?? {}
    },
  },
  created() {
    this.fetchUserProfile();
  },
  methods: {
    async fetchUserProfile() {
      const loader = this.$loading.show();
      try {
        const { id } = this.$route.params;
        this.profile = await this.$store.dispatch(
          'clients/fetchUserById',
          id
        );
      } catch (error) {
        console.log(error);
      } finally {
        loader.hide();
      }
    },
    async showPaymentModal() {
      this.$bvModal.show('modal-lg-create-subscription-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  opacity: 1;
  font-size: 12px !important;
  color: var(--primary) !important;
  background-color: #fff;
  border-bottom: 2px solid var(--primary);
  border-radius: 0 !important;
}

.nav-pills .nav-link {
  font-weight: 900;
  color: var(--dark) !important;
  font-size: 12px !important;
}

.base-icon {
  font-size: 20rem;
}

// .next-of-kin-cta {
//   gap: 1rem;
//   flex-wrap: wrap;
// }
</style>
