<template>
  <div>
    <div class="h4 text-right mb-4">
      <b-icon
        class="pointer text-right"
        icon="x-circle-fill"
        @click="close()"
      ></b-icon>
    </div>
    <div class="title">
      <h4>
        Create Subscription
      </h4>
    </div>
    <div>
      <form @submit.prevent="submit">
        <div class="mt-4">
          <label>Location</label>
          <b-form-select
            v-model="form.locationId"
            :options="locationsList"
          ></b-form-select>
          <template v-if="$v.form.locationId.$error">
            <p v-if="!$v.form.locationId.required" class="error form-error mt-2">
              Please select a location
            </p>
          </template>
        </div>
        <div class="mt-4">
          <label>Product</label>
          <b-form-select
            v-model="form.productId"
            :options="productsList"
          ></b-form-select>
          <template v-if="$v.form.productId.$error">
            <p v-if="!$v.form.productId.required" class="error form-error mt-2">
              Please select a product
            </p>
          </template>
        </div>
        <div class="mt-4">
          <label class="font-weight-bold">
            Units Available: {{unitsAvaialble}}
          </label>
          <rm-input
            v-model="form.units"
            outlined
            placeholder="Enter No. Of Units"
            type="number"
          ></rm-input>
          <template v-if="$v.form.units.$error">
            <p v-if="!$v.form.units.required" class="error form-error">
              Please enter number of units
            </p>
          </template>
        </div>
        <div v-if="selectedProduct.payment_plans?.length > 0">
          <div class="mt-4">
            <label>Select Payment Plan Type</label>
            <b-form-select
              v-model="form.paymentPlanType"
              :options="paymentPlanTypes"
            ></b-form-select>
          </div>
          <div v-if="form.paymentPlanType == 'instalment'">
            <div class="mt-4">
              <label>Select Payment Plan</label>
              <b-form-select
                v-model="form.paymentPlanTypeId"
                :options="paymentPlanList"
              ></b-form-select>
            </div>
          </div>
          <div v-if="selectedPaymentPlan" class="mt-4">
            <label>
              Duration Range: {{selectedPaymentPlan.min_duration}} month(s) 
              to {{selectedPaymentPlan.max_duration}} month(s)
            </label>
            <rm-input
              v-model="form.duration"
              outlined
              placeholder="Enter Duration In Months"
              type="number"
            ></rm-input>
          </div>
        </div>
        <div class="mt-4">
          <label>Total Price</label>
          <rm-input
            outlined
            disabled
            :placeholder="displayTotalPrice"
            type="number"
          ></rm-input>
        </div>
        <div class="mt-4">
          <label>Initial Payment</label>
          <rm-input
            outlined
            disabled
            :placeholder="displayInitialPayment"
            type="number"
          ></rm-input>
        </div>
        <div class="mt-4">
          <label>Payment Method</label>
          <b-form-select
            v-model="form.paymentMethod"
            :options="paymentMethods"
          ></b-form-select>
          <template v-if="$v.form.paymentMethod.$error">
            <p v-if="!$v.form.paymentMethod.required" class="error form-error mt-2">
              Please select a Payment method
            </p>
          </template>
        </div>
        <div  v-if="form.paymentMethod === 'manual'">
          <div class="mt-4">
            <label>Payment Proof</label>
            <rm-upload
              :disabled="!!form.paymentProof"
              @completed="setImageUrl"
            />
            <template v-if="$v.form.paymentProof.$error">
              <p v-if="!$v.form.paymentProof.required" class="error form-error mt-2">
                Please upload proof of payment
              </p>
            </template>
          </div>
          <div class="mt-4">
            <label>
              Bank Reference (Optional)
            </label>
            <rm-input
              v-model="form.bankReference"
              outlined
              placeholder="Enter Bank Reference"
            ></rm-input>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-2">
          <rm-base-button
            :loadingButton="isLoading"
            class="ml-auto"
            text="Create"
            bgColor="primary"
            textColor="white"
          ></rm-base-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required, requiredIf } from 'vuelidate/lib/validators';
import RmUpload from '../../one-properties/components/RmUpload.vue';

export default {
  components: {
    RmUpload
  },
  props: {
    id: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        locationId: null,
        productId: null,
        units: null,
        paymentProof: '',
        paymentMethod: null,
        paymentPlanType: 'outright',
        paymentPlanTypeId: null,
        duration: null
      },
      paymentDetails: {
        total_amount: 0,
        initial_payment: 0,
        monthly_payment: 0,
      },
      paymentMethods: [
        {
          text: 'Wallet',
          value: 'wallet',
        },
        {
          text: 'Manual (Bank Transfer)',
          value: 'manual',
        },
      ],
      paymentPlanTypes: [
        {
          text: 'Outright',
          value: 'outright'
        },
        {
          text: 'Instalment',
          value: 'instalment'
        }
      ],
    };
  },
  validations: {
    form: {
      locationId: { required },
      productId: { required },
      units: { required },
      paymentProof: { requiredIf },
      paymentMethod: { required },
    },
  },
  computed: {
    ...mapGetters({
      locations: "locations/locationsList",
      location: "locations/location",
    }),
    locationsList() {
      return this.locations?.map((location) => ({
        text: location.name,
        value: location.id
      })) ?? []
    },
    products() {
      return this.location?.products ?? []
    },
    productsList() {
      return this.products?.map((product) => ({
        text:
          `${product.name} (₦${this.$convertToCurrency(
            product.unit_price
          )})`,
        value: product.id,
      }));
    },
    selectedProduct() {
      return this.products.find(
        (product) => (product.id === this.form.productId)
      ) ?? {}
    },
    unitsAvaialble() {
      return this.$formatAsMoney(
        this.selectedProduct.units_available || 0
      )
    },
    paymentPlans() {
      return this.selectedProduct.payment_plans ?? []
    },
    paymentPlanList() {
      return this.paymentPlans?.map(item => ({
        text: `${item.min_duration} Month(s) plan`,
        value: item.id,
      }))
    },
    selectedPaymentPlan() {
      return (
        this.form.paymentPlanTypeId && this.paymentPlans.find(
          (plans) => (plans.id === this.form.paymentPlanTypeId)
        )
      ) ?? null
    },
    displayTotalPrice() {
      return `₦${this.$convertToCurrency(
        this.paymentDetails.total_amount
      )}`;
    },
    displayInitialPayment() {
      return `₦${this.$convertToCurrency(
        this.paymentDetails.initial_payment
      )}`;
    },
  },
  watch: {
    'form.locationId': {
      async handler(id) {
        if (!id) return;
        await this.getProducts(id);
      },
    },
    selectedProduct(value) {
      this.calculatePaymentDetails();
    },
    'form.units'(value) {
      this.calculatePaymentDetails();
    },
    selectedPaymentPlan() {
      this.calculatePaymentDetails();
    },
    'form.duration'(value) {
      this.calculatePaymentDetails();
    },
    'form.paymentPlanType'(type) {
      if (type == 'outright') {
        this.form.paymentPlanTypeId = null;
        this.form.duration = null;
      }
    },
  },
  async created () {
    try {
      await this.fetchLocationList();
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    ...mapActions({
      fetchLocationList: "locations/fetchLocationsList",
      fetchLocationById: "locations/fetchLocationById",
      createSubPayment: "sales/createSubscriptionPayment",
    }),
    close() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$bvModal.hide('modal-lg-create-subscription-modal');
    },
    setImageUrl(urls) {
      this.form.paymentProof = urls[0];
      return true;
    },
    async getProducts(locationId) {
      const loader = this.$loading.show();
      try {
        await this.fetchLocationById({ id: locationId });
      } catch (error) {
        console.log(error);
      } finally {
        loader.hide();
      }
    },
    resetPaymentDetails() {
      console.log('reset payment details');
      this.paymentDetails = {
        total_amount: 0,
        initial_payment: 0,
        monthly_payment: 0,
      }
    },
    calculatePaymentDetails() {
      let proceed = this.selectedProduct.unit_price && this.form.units;
      if (!proceed) return this.resetPaymentDetails();
      console.log('calculating payment details');
      if (!this.selectedPaymentPlan) {
        const total = this.selectedProduct.unit_price * this.form.units;
        return this.paymentDetails = {
          total_amount: total,
          initial_payment: total,
          monthly_payment: 0
        }
      }
      proceed = proceed && this.form.duration > 0;
      if (!proceed) return this.resetPaymentDetails();
      if (this.selectedPaymentPlan) {
        // price will increase cause it is installmental payment
        // calculate increase. formula = (duration * price_percentage)/100 * price
        const increase = (
          ((this.form.duration * this.selectedPaymentPlan.price_percentage) / 100) *
          this.selectedProduct.unit_price
        ).toFixed();
        const newPrice = (+this.selectedProduct.unit_price) + (+increase);
        const total = newPrice * this.form.units;
        const initialPayment = parseInt(
          ((this.selectedPaymentPlan.minimum_deposit_percentage / 100) * total).toFixed()
        );

        return this.paymentDetails = {
          total_amount: total,
          initial_payment: initialPayment,
          monthly_payment: 0
        }
      }
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return this.$store.dispatch('toast', {
          variant: 'error',
          message: 'Please fill all required fields',
        });
      }

      const loader = this.$loading.show();
      try {
        const payload = {
          user_id: this.$route.params?.id,
          entity_id: this.form.productId,
          amount: this.paymentDetails.initial_payment,
          purpose: 'new_subscription',
          payment_method: this.form.paymentMethod,
          units: Number(this.form.units),
          duration: +this.form.duration,
          payment_plan_id: this.selectedPaymentPlan ?
            this.selectedPaymentPlan.id :
            null,
          is_instalment: !!(this.selectedPaymentPlan),
          proof_of_payment:this.form.paymentMethod === 'manual'
            ? this.form.paymentProof
            : null,
          bank_reference: this.form.bankReference
            ? this.form.bankReference
            : null, 
        }
        const res = await this.createSubPayment(payload);
        if (res && ['success', 'pending'].includes(res.payment_status)) {
          const message = res.payment_method === 'manual'
            ? 'Payment uploaded successfully. Proceed to approve payment'
            : 'Payment processed successfully';
          this.$store.dispatch('toast', {
            variant: 'success',
            message,
          });
        } else {
          this.$store.dispatch('toast', {
            variant: 'error',
            message: 'Payment failed',
          });
        }
        this.close();
      } catch (error) {
        console.log(error);
      } finally {
        loader.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.custom-select {
  color: #000;
}
</style>
