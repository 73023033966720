<template>
  <div class="bg-white p-4">
    <!-- <b-row no-gutters>
      <b-col cols="3">
        <rm-input
          outlined
          class="mr-2"
          preppend-icon-inner="search"
          placeholder="Search..."
        ></rm-input>
      </b-col>
      <b-col cols="1">
        <rm-base-button text="Search"></rm-base-button>
      </b-col>
      <b-col cols="6">
        <rm-base-button
          class="ml-2"
          text="Hide-Filter"
          bgColor="primary--light"
          textColor="primary"
          ><rm-base-icon slot="post-icon" class="ml-3" name="filter-icon"
        /></rm-base-button>
      </b-col>
      <b-col cols="2">
        <div class="d-flex w-100">
          <rm-base-button
            class="ml-auto"
            text="Export"
            bgColor="primary"
            textColor="white"
            ><rm-base-icon slot="post-icon" class="ml-2" name="export-icon"
          /></rm-base-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2" class="bordered ml-3">
        <rm-drop-down outlined :options="filterOptions" />
      </b-col>
      <b-col cols="2" class="ml-2">
        <rm-drop-down outlined :options="filterOptions" />
      </b-col>
    </b-row>

    <div class="d-flex mb-5">
      <rm-base-button
        class="ml-3"
        text="Filter"
        bgColor="tertiary"
        textColor="white"
      ></rm-base-button>
      <rm-base-button
        class="ml-3"
        text="Clear Filter"
        bgColor="tertiary--light"
        textColor="tertiary"
      ></rm-base-button>
    </div> -->
    <b-table
      ref="walletHistoryTable"
      thead-class="bg-gray-400 text-uppercase text-sm-2"
      tbody-class="text-sm"
      :selectable="true"
      hover
       :busy="isLoading"
      :items="history"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template #table-busy>
        <div class="text-center text-gray my-5">
          <b-spinner class="align-middle"></b-spinner> <br />
          <strong>Loading Data...</strong>
        </div>
      </template>
      <template #head(check)="">
        <input
          type="checkbox"
          :checked="selectAll && history.length == selected.length"
          @click="selectAll = !selectAll"
        />
      </template>
      <template v-slot:cell(check)="{ rowSelected, index }">
        <div class="d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            :checked="rowSelected"
            @click="handleRowSelect(rowSelected, index)"
          />
        </div>
      </template>
      <template #cell(type)="{ item }">
        <div class="d-flex justify-content-center">
          <span
            class="text-center"
            :class="
              item.type == 'credit'
              ? 'success-pill'
              : 'failed-pill'
            "
          >
            {{ item.type }}
          </span>
        </div>
      </template>
      <template #cell(status)="">
        <div class="d-flex justify-content-center">
          <span class="text-center success-pill">
            success
          </span>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="paging.totalRows"
      v-model="paging.currentPage"
      :total-rows="paging.totalRows"
      :per-page="paging.perPage"
      aria-controls="walletHistoryTable"
      @change="changePage"
    />
  </div>
</template>

<script>
import formatDate from '@/mixins/formatDate.js';
import exportData from '@/mixins/exportData.js';

export default {
  props: {
    realtorId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      selectAll: false,
      selected: [],
      history: [],
      filterOptions: {},
      paging: {
        currentPage: 1,
        totalRows: null,
        perPage: null,
      },
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: "check",
          // sortable: true,
          label: "",
          class: "text-center",
        },
        {
          key: "reference",
          // sortable: true,
          label: "Reference",
          class: "text-center",
        },
        {
          key: "date",
          sortable: false,
          label: "Date",
          class: "text-center",
        },
        {
          key: "amount",
          sortable: false,
          label: "Amount",
          class: "text-center",
          // class: "w-25",
        },
        {
          key: "type",
          sortable: false,
          label: "Type",
          class: "text-center",
          // class: "w-25",
        },
        {
          key: "balance",
          sortable: false,
          label: "Balance",
          class: "text-center",
          // class: "w-25",
        },
        {
          key: "description",
          sortable: false,
          label: "Description",
          class: "text-center",
          // class: "w-25",
        },
        {
          key: "status",
          sortable: false,
          label: "Status",
          class: "text-center",
        },
      ],
    };
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
  created() {
    this.loadData();
  },
  mixins: [formatDate, exportData],
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.walletHistoryTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.walletHistoryTable.clearSelected();
    },
    selectRow(index) {
      this.$refs.walletHistoryTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.walletHistoryTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },
    changePage(pageNo) {
      this.paging.currentPage = pageNo;
      this.loadData();
    },
    async loadData() {
      this.isLoading = true;
      try {
        const params = {
          user_id: this.realtorId,
          page: this.paging.currentPage,
        };
        await this.fetchWalletHistory(params)
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchWalletHistory(params) {
      const { rows, paging } = await this.$store.dispatch(
        "admin/fetchWalletHistory",
        params
      );
      this.history = rows.map((row) => {
        const obj = {};
        obj.reference = row.payment_ref;
        obj.date = this.formatDate(row.created_at);
        obj.amount = `₦${this.$formatCurrency(row.amount / 100)}`;
        obj.type = row.position;
        obj.balance = `₦${this.$formatCurrency(row.new_balance / 100)}`;
        obj.description = row.description;
        return obj;
      });
      
      this.paging.currentPage = paging.currentPageNo;
      this.paging.totalRows = paging.total;
      this.paging.perPage = paging.perPage;
    },
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  border-left: 1px solid var(--primary);
}
.success-pill {
  border-radius: 8px;
  padding: 4px 16px;
  max-width: 83px;
  color: #00CD84;
  background: #E8FBF4;
}
.failed-pill {
  border-radius: 8px;
  padding: 4px 16px;
  max-width: 83px;
  color: #DF1B00;
  background: #FFEEEB;
}
</style>
