<template>
  <div class="mt-5">
    <div class="balance-card">
      <p class="text-white">Wallet Balance</p>
      <h2 class="text-white">
        ₦{{ $formatCurrency(balance / 100) }}
      </h2>
      <p class="text-white">
        at Today, {{ currentDate | luxon }}
      </p>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "BalanceCard",
  props: {
    balance: {
      type: [Number, String],
      default: 0
    },
  },
  computed: {
    currentDate() {
      return DateTime.now().toString();
    },
  }
};
</script>

<style lang="scss" scoped>
.balance-card {
  padding: 2rem;
  border-radius: 8px;
  margin-left: 1rem;
  background: #232526; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #414345,
    #232526
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #414345,
    #232526
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
